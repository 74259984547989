










































































































































import {Component, Vue} from 'vue-property-decorator'
import {BlogPostStatus} from '~/components/data-class/data-class'
import {createRequest, serverBaseURL} from '~/utils/network-request'
import {BlogPermission} from '~/utils/permissions'
import {BlogTagList} from '@afterschool.dev/as-data-admin'

@Component({
    components: {},
    metaInfo() {
        return {
            title: 'Blog List'
        }
    }
})

export default class BlogList extends Vue {
    static permission = BlogPermission.EditBlog

    selectedMember = ''
    selectedStatus = -1
    selectedTag = ''

    keyword = ''

    status = ''

    currentPage: number = 1
    totalCount: number = 0
    step: number = 10

    BlogPostStatus = BlogPostStatus
    BlogTagList = BlogTagList

    selectClass = {
        [BlogPostStatus.PENDING]: 'select-pending',
        [BlogPostStatus.PUBLISHED]: 'select-published',
        [BlogPostStatus.HIDDEN]: 'select-hidden',
    }

    statusOptions: Array<{ value: number, label: string }> = [
        {
            value: BlogPostStatus.PENDING,
            label: 'Pending'
        },
        {
            value: BlogPostStatus.PUBLISHED,
            label: 'Published'
        },
        {
            value: BlogPostStatus.HIDDEN,
            label: 'Hidden'
        }
    ]
    statusFilterOptions: Array<{ value: number, label: string }> = [
        {
            value: -1,
            label: 'All Status'
        },
        {
            value: BlogPostStatus.PENDING,
            label: 'Pending'
        },
        {
            value: BlogPostStatus.PUBLISHED,
            label: 'Published'
        },
        {
            value: BlogPostStatus.HIDDEN,
            label: 'Hidden'
        }
    ]

    tableData: Array<{}> = []

    // Change Status

    statusChanged(index, row) {
        const body = {
            status: row.status.toString()
        }

        const blogId = row._id
        createRequest(`/blog/blog-post/${blogId}`, 'patch', {}, body).send()
            .then((res) => {
                this.$message({
                    message: 'Blog status changed successfully!',
                    type: 'success'
                })
            })
            .catch((e) => {
                this.$message({
                    message: 'Error occurred!',
                    type: 'error'
                })
            })
    }

    created() {
        const page = Number(this.$route.query['page'])
        if (!isNaN(page)) {
            this.currentPage = page
        }
        this.selectedMember = this.$route.query['member-id'] as string || ''
        this.selectedStatus = Number(this.$route.query['status']) || -1
        this.selectedTag = this.$route.query['tag'] as string || ''
        this.keyword = this.$route.query['keyword'] as string || ''

        this.updateBlogs(this.currentPage)
    }

    updateBlogs(page = 1) {
        this.currentPage = page
        const q: any = {
            page: this.currentPage
        }
        const params: { [key: string]: string } = {
            status: '-1,0,1,2',
            skip: (this.currentPage - 1) * this.step + '',
            limit: this.step + '',
            count: 'true'
        }

        if (this.selectedMember !== '') {
            params['member_id'] = this.selectedMember
            q['member-id'] = this.selectedMember
        }

        if (this.selectedStatus !== -1) {
            params['status'] = this.selectedStatus.toString()
            q['status'] = this.selectedStatus.toString()
        }

        if (this.selectedTag !== '') {
            params['tags'] = this.selectedTag
            q['tag'] = this.selectedTag
        }

        if (this.keyword) {
            params['keyword'] = this.keyword
            q['keyword'] = this.keyword
        }

        createRequest('/blog/blog-posts', 'get', params).send()
            .then((res) => {
                this.tableData = res.data.blog_posts
                this.totalCount = res.data['count']
            }).catch((e) => {
            console.error(e)
        })
        this.$router.push({query: q})
    }

    midClicked(mid: string) {
        this.selectedMember = (this.selectedMember === mid) ? '' : mid
        this.updateBlogs()
    }

    async downloadCsv() {
        const fileLink = document.createElement('a')
        fileLink.href = serverBaseURL + 'blog/blog.csv?auth=' + await this.$auth.getAccessToken()
        document.body.appendChild(fileLink)
        fileLink.click()
        document.body.removeChild(fileLink)
    }
}

